// TODO: Create function that add dyamically query params or use axios method
export const ENDPOINTS = {
  LOGIN: '/login/',
  LOGOUT: '/logout/',
  GET_PERCENT_PASSED: '/metrics/reports/percent_passed',
  GET_CLIENTS_SUMMARY: '/clients/summary',
  GET_CLIENTS: '/clients',
  GET_CLIENT: (id: string) => `/clients/${id}`,
  GET_REPORT: (id: string) => `/reports/${id}`,
  GET_ANA_REPORTS_BY_CLIENT: (clientId: string, metagroup?: string) => {
    let url = `/anagraphy/reports/client/${clientId}`;
    if (metagroup) url += `?metagroup=${metagroup}`;
    return url;
  },
  GET_ANA_REPORTS_BY_REPORT: (reportId: string) =>
    `/anagraphy/reports/report/${reportId}`,
  GET_REPORTS_WITH_FILTERS: (
    clientId?: string,
    reportId?: string,
    ordering?: { field: string; order: string },
    metagroup?: string
  ) => {
    let url = '/reports';
    if (clientId && reportId) {
      url += `?client_id=${clientId}&report_id=${reportId}`;
    } else if (reportId) {
      url += `?report_id=${reportId}`;
    } else if (clientId) {
      url += `?client_id=${clientId}`;
    }
    if (ordering) {
      url += `&${ordering.field}_order=${ordering.order}`;
    }
    if (metagroup) {
      url += `&metagroup=${metagroup}`;
    }
    return url;
  },
  GET_ANAGRAPHIC_REPORTS_WITH_FILTERS: (
    clientId?: string,
    page?: number,
    perPage?: number,
    order?: string,
    orderBy?: string,
    searchValue?: string,
    searchBy?: string
  ) => {
    let url = `/reports/anagraphic?client_id=${clientId}`;
    if (page !== undefined) url += `&page=${page}`;
    if (perPage) url += `&per_page=${perPage}`;
    if (order && orderBy) url += `&sort_order=${order}&sort_by=${orderBy}`;
    if (searchValue && searchBy)
      url += `&filter_value=${searchValue}&filter_by=${searchBy}`;

    return url;
  },
  PUT_ANAGRAPHIC_REPORT: (clientId: string) =>
    `/reports/anagraphic?client_id=${clientId}`,
  POST_WHITELIST_REPORT: '/whitelists',
  POST_REFRESH_ALL_REPORTS: (clientId: string) =>
    `/reports/refresh_all/${clientId}`,
};
