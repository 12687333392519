import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import BasicSelect, { MenuItemProps } from '../Select/BasicSelect';
import SearchInput from '../SearchInput';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import {
  EditableAnagraphicTableCellProps,
  EditableAnagraphicTableRowProps,
  EditableAnagraphicTableProps,
  EditableAnagraphicTableHeadCellProps,
  Order,
} from '../../schema/EditableAnagraphicTableInterface';
import { useGetAnagraphicReportsWithFilters } from '../../api/ReportsAPI';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import {
  Alert,
  AlertTitle,
  DialogContentText,
  Grow,
  Snackbar,
  TablePagination,
} from '@mui/material';
import { debounce } from '../../utils/general';
import TableRowsLoader from './TableRowsLoader';
import { useDispatch, useSelector } from 'react-redux';
import { updateTableRows } from '../../actions/anagraphicTableActions';
import styles from './Table.module.css';
import { twMerge } from 'tailwind-merge';
import { BaseTableAction } from './BaseTable';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Modal from '../Modal';
import { useApiCall } from '../../api/ApiManager';
import { ENDPOINTS } from '../../api/EndPoints';

const EditableAnagraphicTableCell: React.FC<
  EditableAnagraphicTableCellProps
> = ({
  initialValue,
  currentValue,
  isEditable = false,
  alternativeValues,
  onToggleSelect,
  classNames = '',
}) => {
  let cellContent;
  if (alternativeValues && isEditable) {
    const menuItems = alternativeValues.filter(
      (item) =>
        item.value.toString() !== initialValue?.toString() &&
        item.value.toString() !== ''
    );
    cellContent = (
      <BasicSelect
        defaultSelected={currentValue?.toString()}
        menuItems={menuItems}
        isRequired={true}
        variant="standard"
        classNames="h-full bg-gray-100"
        toggleSelectState={onToggleSelect}
      />
    );
  } else {
    cellContent = currentValue;
  }
  return (
    <TableCell
      component="th"
      scope="row"
      align="center"
      className={twMerge('w-auto h-full', classNames)}
    >
      {cellContent}
    </TableCell>
  );
};

const EditableAnagraphicTableHeadCell: React.FC<
  EditableAnagraphicTableHeadCellProps
> = ({
  columnName,
  onRequestSort,
  onRequestSearch,
  order,
  orderBy,
  searchBy,
  searchValue,
  classNames = '',
}) => {
  const createSortHandler =
    (property: keyof EditableAnagraphicTableRowProps['rowData']) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const createSearchHandler =
    (property: keyof EditableAnagraphicTableRowProps['rowData']) =>
    (event: any) => {
      onRequestSearch(event, property);
    };

  const defaultSerchValue = searchBy === columnName ? searchValue : undefined;

  return (
    <TableCell
      key={columnName}
      align="center"
      padding="normal"
      sortDirection={orderBy === columnName ? order : false}
      className={`!bg-[#FAFAFC] min-w-28 ${classNames}`}
      component="th"
      scope="row"
    >
      <TableSortLabel
        active={orderBy === columnName}
        direction={orderBy === columnName ? order : 'asc'}
        onClick={createSortHandler(columnName)}
      >
        <Typography fontSize="1rem" fontWeight={600}>
          {columnName}
        </Typography>

        {orderBy === columnName ? (
          <Box component="span" sx={visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
      </TableSortLabel>
      <SearchInput
        placeholder="Cerca"
        variant="standard"
        size="small"
        searchIcon={false}
        onChange={createSearchHandler(columnName)}
        value={defaultSerchValue}
      />
    </TableCell>
  );
};

const EditableAnagraphicTable: React.FC<EditableAnagraphicTableProps> = ({
  clientId,
  classNames = '',
}) => {
  const actionsColumn = {
    name: 'actions',
    isFocus: false,
    enableCopy: false,
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] =
    useState<keyof EditableAnagraphicTableRowProps['rowData']>('id');

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchBy, setSearchBy] =
    useState<keyof EditableAnagraphicTableRowProps['rowData']>('');

  const [whiteListModalOpen, setWhiteListModalOpen] = useState<boolean>(false);
  const [whiteListAssetId, setWhiteListAssetId] = useState<number>(0);
  const [whiteListSelectedMenuItem, setWhiteListSelectedMenuItem] =
    useState<string>();
  const [whiteListAssetMenuItems, setWhiteListAssetMenuItems] = useState<
    MenuItemProps[]
  >([] as MenuItemProps[]);
  const [whitelistModalConfirmLoading, setWhitelistModalConfirmLoading] =
    useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>();

  const { makeCall } = useApiCall();

  const handleWhiteListOnClick = (assetId: number, menuItems: string[]) => {
    setWhiteListAssetId(assetId);
    setWhiteListAssetMenuItems(
      menuItems.map((item: string) => ({
        value: item,
        label: item,
      }))
    );
    setWhiteListModalOpen(true);
  };

  const handleWhiteListModalSubmit = (event: any) => {
    event.preventDefault();
    if (!whiteListSelectedMenuItem) {
      setSnackbarMessage('Seleziona il campo da aggiungere alla whitelist');
    } else {
      setWhitelistModalConfirmLoading(true);
      makeCall(ENDPOINTS.GET_ANA_REPORTS_BY_REPORT(reportId), 'get')
        .then(async (response) => {
          const postData = {
            id_client: parseInt(clientId),
            id_ana_report: response.data.report_id,
            id_asset: whiteListAssetId,
            field_name: whiteListSelectedMenuItem,
          };
          await makeCall(ENDPOINTS.POST_WHITELIST_REPORT, 'post', postData);
        })
        .then(() => {
          setSnackbarMessage("L'elemento è stato aggiunto alla whitelist");
          handleWhiteListModalClose();
          setWhitelistModalConfirmLoading(false);
          setWhiteListSelectedMenuItem('');
        })
        .catch((error: any) => {
          console.error('Errore durante la richiesta:', error);
          setSnackbarMessage('Ops...qualcosa è andato storto, riprova!');
          setWhitelistModalConfirmLoading(false);
        });
    }
  };

  const handleWhiteListModalClose = () => {
    setWhiteListModalOpen(false);
  };

  const handleSnakbarMessageOnClose = () => {
    setSnackbarMessage('');
  };

  const tableActions = [
    {
      label: 'Aggiungi alla whitelist',
      onClick: handleWhiteListOnClick,
      icon: <PostAddIcon />,
    },
  ];

  const {
    reportId,
    reportColumns,
    reportRows,
    pagination,
    isPending,
    errorMessage,
  } = useGetAnagraphicReportsWithFilters(
    clientId,
    page,
    rowsPerPage,
    order,
    orderBy?.toString(),
    searchValue,
    searchBy.toString()
  );

  const tableColumns =
    tableActions && reportColumns
      ? reportColumns.concat([actionsColumn])
      : reportColumns;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string | number
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleRequestSearch = (event: any, property: string | number) => {
    setSearchValue(event);
    setSearchBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setRowsPerPage(parseInt(value, 10));
  };

  if (errorMessage) {
    return (
      <div className="relative flex w-full h-[70vh] justify-center items-center">
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Ops...qualcosa è andato storto, riprova!
        </Alert>
      </div>
    );
  }

  if (isPending && reportColumns.length === 0) {
    return (
      <div className="relative flex w-full h-[70vh] justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  const EditableAnagraphicTableBody: React.FC = () => {
    const rowsSelector = useSelector((state: any) => state.rows);

    const mergedRows = reportRows?.map((reportRow: any) => {
      const row = reportRow.rowData;
      const corrRow = rowsSelector.find((el: any) => el.id === row.id);
      if (corrRow) {
        const inEditCells: string[] = Object.keys(corrRow).filter(
          (key) => key !== 'id'
        );
        return {
          rowData: { ...row, ...corrRow },
          rowProperty: {
            inEditCells: inEditCells,
            editableCells: reportRow.rowProperty.editableCells,
          },
        };
      }
      return reportRow;
    });
    const dispatch = useDispatch();

    if (isPending && tableColumns.length !== 0) {
      return (
        <TableBody>
          <TableRowsLoader numRows={10} numCols={tableColumns.length} />
        </TableBody>
      );
    }

    const handleToggleSelect =
      (
        rowId: string,
        columnName: keyof EditableAnagraphicTableRowProps['rowData']
      ) =>
      (newValue: string) => {
        dispatch(updateTableRows({ id: rowId, [columnName]: newValue }));
      };

    return (
      <TableBody>
        {mergedRows.map((row: any, rowIndex: number) => (
          <TableRow
            key={`${row.rowData.name}_${rowIndex}`}
            sx={{
              '&:last-child td, &:last-child th': {
                border: 0,
              },
            }}
          >
            <TableCell
              component="th"
              scope="row"
              align="center"
              className={`${styles['sticky-column']} !p-0`}
            >
              {tableColumns.slice(0, 4).map((column: any, index: number) => {
                const initialValue = reportRows[rowIndex].rowData[column.name];
                const currValue = initialValue;
                const widthClassNames =
                  index >= 1 ? 'w-32 max-w-32' : 'w-20 max-w-20';
                return (
                  <EditableAnagraphicTableCell
                    key={`${column.name}_${row.rowData[column.name]}_${
                      index * 2
                    }`}
                    initialValue={initialValue}
                    currentValue={currValue}
                    onToggleSelect={() => {}}
                    classNames={`!border-b-0 !break-words ${widthClassNames}`}
                  />
                );
              })}
            </TableCell>
            {tableColumns.slice(4).map((column: any, index: number) => {
              const isInEditable = row.rowProperty.inEditCells.includes(
                column.name.toString()
              );
              const isEditable = row.rowProperty.editableCells.includes(
                column.name.toString()
              );
              const alternativeValues =
                column.possibleValues &&
                column.possibleValues.map((value: any) => ({
                  value,
                  label: value,
                }));
              const initialValue = reportRows[rowIndex].rowData[column.name];
              const currValue = row.rowData[column.name];
              if (column.name === 'actions') {
                return (
                  <TableCell
                    key={`${column.name}_${row[column.name]}_${index}`}
                    align="center"
                  >
                    <div className="flex flex-row justify-center">
                      {tableActions?.map((action) => {
                        const whiteListMenuItems =
                          row.rowProperty.editableCells;
                        return (
                          <BaseTableAction
                            key={`${action.label}_${row['id']}`}
                            {...action}
                            onClick={() =>
                              action.onClick(
                                row.rowData['id'],
                                whiteListMenuItems
                              )
                            }
                          />
                        );
                      })}
                    </div>
                  </TableCell>
                );
              }
              return (
                <EditableAnagraphicTableCell
                  key={`${column.name}_${row.rowData[column.name]}_${
                    index * 2
                  }`}
                  initialValue={initialValue}
                  currentValue={currValue}
                  isEditable={isEditable || isInEditable}
                  alternativeValues={alternativeValues}
                  onToggleSelect={handleToggleSelect(
                    row.rowData['id'],
                    column.name.toString()
                  )}
                />
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  return (
    <Grow in={!isPending || reportColumns.length !== 0} timeout={700}>
      <div>
        <TableContainer
          component={Paper}
          className={`shadow-none w-full mb-2 ${classNames}`}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={`${styles['sticky-column']} !z-[3] !p-0 !border-b-0`}
                >
                  {tableColumns
                    .slice(0, 4)
                    .map((column: any, index: number) => {
                      const widthClassNames =
                        index >= 1 ? 'w-32 min-w-32' : 'w-20 min-w-20';
                      return (
                        <EditableAnagraphicTableHeadCell
                          key={index}
                          columnName={column.name.toString()}
                          onRequestSort={handleRequestSort}
                          onRequestSearch={debounce(handleRequestSearch, 1000)}
                          order={order}
                          orderBy={orderBy.toString()}
                          searchValue={searchValue}
                          searchBy={searchBy}
                          classNames={widthClassNames}
                        />
                      );
                    })}
                </TableCell>
                {tableColumns.slice(4).map((column: any, index: number) => (
                  <EditableAnagraphicTableHeadCell
                    key={index}
                    columnName={column.name.toString()}
                    onRequestSort={handleRequestSort}
                    onRequestSearch={debounce(handleRequestSearch, 1000)}
                    order={order}
                    orderBy={orderBy.toString()}
                    searchValue={searchValue}
                    searchBy={searchBy}
                  />
                ))}
              </TableRow>
            </TableHead>
            <EditableAnagraphicTableBody />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={pagination.totalRecords || 0}
          rowsPerPage={rowsPerPage}
          page={page || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Modal
          title={'Sei sicuro di voler aggiungere alla WhiteList?'}
          open={whiteListModalOpen}
          onSubmit={handleWhiteListModalSubmit}
          confirmButtonLoading={whitelistModalConfirmLoading}
          onClose={handleWhiteListModalClose}
        >
          <DialogContentText>
            <div className="flex flex-row">
              Seleziona il campo per l'asset {whiteListAssetId}:
              <BasicSelect
                menuItems={whiteListAssetMenuItems}
                isRequired
                toggleSelectState={(value) =>
                  setWhiteListSelectedMenuItem(value)
                }
                classNames="!w-1/2"
              />
            </div>
          </DialogContentText>
        </Modal>
        <Snackbar
          open={snackbarMessage !== undefined && snackbarMessage !== ''}
          onClose={handleSnakbarMessageOnClose}
          autoHideDuration={2000}
          message={snackbarMessage}
        />
      </div>
    </Grow>
  );
};
export default EditableAnagraphicTable;
