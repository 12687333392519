import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CopyToClipboardButton from '../CopyToClipboardButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

export interface BaseTableRowProps {
  [key: string]: string;
}

export interface BaseTableColumnProps {
  name: keyof BaseTableRowProps;
  isFocus?: boolean;
  enableCopy?: boolean;
}

export interface TableActionsProps {
  id?: string;
  label: string;
  onClick: (rowId: any) => void;
  icon?: JSX.Element;
  disabled?: boolean;
}

interface BaseTableProps {
  columns: BaseTableColumnProps[];
  rows: BaseTableRowProps[];
  actions?: TableActionsProps[];
  rowsCount?: boolean;
  classNames?: string;
}

export const BaseTableAction: React.FC<TableActionsProps> = ({
  label,
  onClick,
  icon,
  disabled,
}) => {
  if (icon)
    return (
      <IconButton
        size="small"
        aria-label={label}
        onClick={onClick}
        title={label}
        disabled={disabled}
      >
        {icon}
      </IconButton>
    );
  return (
    <Button size="small" onClick={onClick} disabled={disabled}>
      {label}
    </Button>
  );
};

const BaseTable: React.FC<BaseTableProps> = ({
  columns,
  rows,
  rowsCount = false,
  classNames = '',
  actions,
}) => {
  const actionsColumn = {
    name: 'actions',
    isFocus: false,
    enableCopy: false,
  };
  const orderedColumns = columns.sort((a, b) =>
    a.isFocus === b.isFocus ? 0 : a.isFocus ? -1 : 1
  );

  const tableColumns = actions
    ? orderedColumns.concat([actionsColumn])
    : orderedColumns;

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer component={Paper} className={`shadow-none ${classNames}`}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableColumns.map((column: BaseTableColumnProps) => {
                const columnToString = rows.map((row) => row[column.name]);
                return (
                  <TableCell
                    key={column.name}
                    align="center"
                    className={`border-none ${
                      column.isFocus ? '!bg-slate-200' : '!bg-[#FAFAFC]'
                    }`}
                  >
                    <div
                      className={`flex flex-row ${
                        column.enableCopy ? 'justify-between' : 'justify-center'
                      } items-center`}
                    >
                      <Typography fontSize="1rem" fontWeight={600}>
                        {column.name}
                      </Typography>
                      {column.enableCopy && (
                        <CopyToClipboardButton
                          elementToCopy={columnToString.toString()}
                        />
                      )}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index: number) => (
              <TableRow
                key={`${row.name}_${index}`}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                {tableColumns.map((column, index) => (
                  <TableCell
                    key={`${column.name}_${row[column.name]}_${index}`}
                    align="center"
                  >
                    {column.name === 'actions' ? (
                      <div className="flex flex-row justify-center">
                        {actions?.map((action) => (
                          <BaseTableAction
                            key={`${action.label}_${row['id']}`}
                            {...action}
                            onClick={() => action.onClick(row['id'])}
                          />
                        ))}
                      </div>
                    ) : (
                      row[column.name]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rowsCount && (
        <div className="flex justify-start">
          <div className="mx-2 my-0 py-2">
            <p className="text-base text-gray-700">{`Totale righe: ${rows.length}`}</p>
          </div>
        </div>
      )}
    </Paper>
  );
};
export default BaseTable;
